<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Booking Order</h2>
            </CCardHeader>
            <CCardBody>
                <CTabs>
                    <CTab title="Customer" id="tab-1" active>
                        <br>
                        <div class="col-xs-6 text-light pb-2">
                            <button id="add" class="btn btn-success"                   
                                @click="addClick()">
                                <i class="fa fa-plus"></i> Add
                            </button>
                            &nbsp;
                            <button id="refresh" class="btn btn-primary"                   
                                @click="refreshClick()">
                                <i class="fa fa-refresh"></i> Refresh
                            </button>
                        </div>

                        <booking-order-grid-customer ref="grid" :key="gridReload" :editCustomerClick="editCustomerClick" :viewClick="viewClick" :deleteClick="deleteClick" :addClick="addClick"  />
                    </CTab>

                    <CTab title="Item" id="tab-2">
                        <br>
                        <CSelect id="StorageId" class="font-weight-bold" :options="StorageIdData" :value.sync="StorageId" @change="onChange()"/>
                        <hr>
                        <CRow>
                            <CCol>
                                <button id="view" class="btn btn-primary" style="float:left"
                                    @click="exportItemExcel()">
                                    <i class="fa fa-file-excel-o"></i> Excel
                                </button>
                            </CCol>
                        </CRow>

                        <hr>
                        <booking-order-grid-item ref="bookingOrderGridItem" :key="gridReload" v-bind:storage-id="this.StorageId"/>
                    </CTab>

                    <CTab title="Tipe Item - Stock" id="tab-3">
                        <br>
                        <CSelect id="StorageId" class="font-weight-bold" :options="StorageIdData" :value.sync="StorageId" @change="onChange()"/>
                        <hr>
                        <CRow>
                            <CCol>
                                <button id="view" class="btn btn-primary" style="float:left"
                                    @click="exportItemStockExcel()">
                                    <i class="fa fa-file-excel-o"></i> Excel
                                </button>
                            </CCol>
                        </CRow>

                        <hr>
                        <booking-order-grid-item-type-stock ref="bookingOrderGridItemTypeStock" :key="gridReload" v-bind:storage-id="this.StorageId"/>
                    </CTab>

                    <CTab title="Tipe Item - Formula" id="tab-4">
                        <br>
                        <CSelect id="StorageId" class="font-weight-bold" :options="StorageIdData" :value.sync="StorageId" @change="onChange()"/>
                        <hr>
                        <CRow>
                            <CCol>
                                <button id="view" class="btn btn-primary" style="float:left"
                                    @click="exportItemFormulaExcel()">
                                    <i class="fa fa-file-excel-o"></i> Excel
                                </button>
                            </CCol>
                        </CRow>

                        <hr>
                        <booking-order-grid-item-type-formula ref="bookingOrderGridItemTypeFormula" :key="gridReload" v-bind:storage-id="this.StorageId"/>
                    </CTab>
                </CTabs>
                
                <booking-order-form  ref="BookingOrderForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import BookingOrderGridCustomer from './Grid/BookingOrderGridCustomer.vue';
import BookingOrderGridItem from './Grid/BookingOrderGridItem.vue';
import BookingOrderGridItemTypeStock from './Grid/BookingOrderGridItemTypeStock.vue';
import BookingOrderGridItemTypeFormula from './Grid/BookingOrderGridItemTypeFormula.vue';
import BookingOrderForm from './Component/BookingOrderForm.vue';
import BookingOrderServices from './Script/BookingOrderServices.js';

export default {
    name: 'BookingOrder',
    components: {
        'booking-order-grid-customer': BookingOrderGridCustomer,
        'booking-order-grid-item': BookingOrderGridItem,
        'booking-order-grid-item-type-stock': BookingOrderGridItemTypeStock,
        'booking-order-grid-item-type-formula': BookingOrderGridItemTypeFormula,
        'booking-order-form': BookingOrderForm,
    },
    async mounted () {
        this.StorageIdData = await BookingOrderServices.getStorageId();
        this.StorageId = this.StorageIdData[0].value;
    },
    data(){
        return{
            StorageId: 'Gudang-CKP',
            StorageIdData : [],
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            if (this.$refs.grid.hf_ID_Filter == ''){
                this.$swal("Info", "Harap untuk memilih Booking sebelum menekan tombol Create", "info");
            }
            else{
                var validate = BookingOrderServices.validateDuplicateSelectedData(this.$refs.grid.hf_ID_Filter);
                
                if(validate == 'not match') {
                    this.$swal("Info", "Harap untuk memilih Booking dengan No. Purchase Order yang sama", "info");
                }
                else {
                    var selectedData = validate;
                    var errorCount = 0;
                    var boIdList = [];
                    var po_id = selectedData[0].purchase_order_id

                    for (let i = 0; i<selectedData.length; i++) {
                        if (selectedData[i].quantity_ready == 0) {
                            errorCount++;
                        }

                        boIdList.push(selectedData[i].booking_order_id)
                    }

                    if (errorCount > 0) {
                        this.$swal("Error", "Quantity Ready masih 0 pada salah satu dari data Booking yang dipilih", "error");
                    }
                    else {
                        var bo_id = boIdList.join('|');
                        const routeData = this.$router.resolve({ name: 'Booking Purchase Order Form', params: { formtype:'Add', boId:bo_id, poId:po_id, view:'new' } });
                        window.open(routeData.href, '_blank');
                    }
                }
            }            
        },
        refreshClick(){
            // this.$refs.grid.saveBookingOrderItem();
            this.reload();
        },
        viewClick(data, view){
            this.$refs.BookingOrderForm.editClick(data, view);
        },
        editCustomerClick(data){            
            const bookingOrderData = {
                purchase_order_id: data.purchase_order_id,
                item_id: data.item_id,
                uom: data.uom,
                quantity: data.quantity,
                quantity_production: data.quantity_production,
                quantity_ready: data.quantity_ready,
                price_per_unit: data.price_per_unit,
                price: data.price,
                vat_per_unit: data.vat_per_unit,
                vat: data.vat,
                discount1: data.discount1,
                discount2: data.discount2,
                discount3: data.discount3,
                discount4: data.discount4,
                discount5: data.discount5,
                discount_cash: data.discount_cash,
                discount1_amount: data.discount1_amount,
                discount2_amount: data.discount2_amount,
                discount3_amount: data.discount3_amount,
                discount4_amount: data.discount4_amount,
                discount5_amount: data.discount5_amount,
                discount_cash_amount: data.discount_cash_amount,
                tax_amount: data.tax_amount,
            }
            
            const variables = {
                id: data.booking_order_id,
                data: bookingOrderData
            }
            
            this.$swal(sweet_alert.update_inline_grid).then((result) => {
                if (result.isConfirmed == true) {
                    BookingOrderServices.editQuery(variables).then(res => {
                        // this.reload();
                        this.$swal("Info", response.successUpdate, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        async deleteClick(id){
            const variables = {
                id: id
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    BookingOrderServices.deleteQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        onChange(){
            this.reload();
        },
        reload(){
            this.gridReload++;
        },
        async exportItemExcel() {
            this.$refs.bookingOrderGridItem.exportExcel(this.StorageId);
        },
        async exportItemStockExcel() {
            this.$refs.bookingOrderGridItemTypeStock.exportExcel(this.StorageId);
        },
        async exportItemFormulaExcel() {
            this.$refs.bookingOrderGridItemTypeFormula.exportExcel(this.StorageId);
        }
    }
}
</script>

<style scoped>
</style>